.welcomeInk {
    padding: 15px;
    margin: 0 0 10px 0;
    color: white;
    font-size: 19.2px;
    text-align: center;
    font-family: Roboto,arial,sans-serif;
    /* font-family: 'Abril Fatface', cursive; */

    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 22px;
    }
}

.counterSub {

    color: #fff;
    font-size: 18px;
    font-weight: bold;
    line-height: normal;

    @media (max-width: 600px) {
        font-size: 18px;
    }

}

